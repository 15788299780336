import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, Field } from "formik";

import { AppImages } from "../../../../Constants/Images";
import {
  ShowsSchema,
  PostponeShowsSchema,
} from "../../../../Utilites/Validation";
import * as Yup from "yup";
import TextInput from "../../../../Components/TextInput";
import CustomSelectPicker from "../../../../Components/CustomSelect";
import CustomDatepicker from "../../../../Components/CustomDatePicker";
import { ApiUrl } from "../../../../Constants/Api";
import { apiCall, getApiCall } from "../../../../Utilites/Api";
import CustomRadio from "../../../../Components/CustomRadio";
import CustomTextArea from "../../../../Components/CustomTextArea";
import moment from "moment";
import CustomModal from "../../../../Components/Modal";
import { convertToDbFormat } from "../../../../Utilites/Date";
import { toast } from "react-toastify";
import SearchInput from "../../../../Components/SearchInput";
import { useNavigate,useLocation, useParams } from "react-router-dom";
import { InputAdornment } from "@mui/material";

const showTypes = [
  { id: 1, value: "Headline", label: "Headline" },
  { id: 2, value: "Support", label: "Support" },
  { id: 3, value: "Festival", label: "Festival" },
];

const taxMethods = [
  {
    id: 1,
    title: "Inclusive",
    value: "inclusive",
  },
];


const ShowsForm = (props) => {
  const {
    handleModalClose,
    venueList,
    modalProps,
    tourItemObj,
    handleSubmitForm,
    handleSetAddExpandModal,
    singleShowDetails,
    tour_id,
    field,
    getVenueList,
    onValueReturn,
    shouldHitAPI
  } = props;
  const sendDataToParent = () => {
    onValueReturn("someValue"); // Send value to parent
  };
  const currentDate = new Date();
  const dateTime = new Date(modalProps?.item?.start_date);
  let tour_Start_Date = modalProps?.item?.start_date;
  
  if (currentDate > dateTime) {
    tour_Start_Date = convertToDbFormat(currentDate);
  } else {
    tour_Start_Date = convertToDbFormat(dateTime);
  }
  
  //const tour_Start_Date = modalProps?.item?.start_date;
  //const tour_Start_Date = modalProps?.item?.start_date < new Date().toLocaleDateString() ? new Date().toLocaleDateString() : modalProps?.item?.start_date;
  // const tour_end_Date = modalProps?.item?.end_date;
  const currentEndDate = new Date();
  currentEndDate.setMonth(currentEndDate.getMonth() + 2);
  const tour_end_Date_time = new Date(modalProps?.item?.end_date);
  let tour_end_Date = modalProps?.item?.end_date;
  if (currentEndDate > tour_end_Date_time) {
    tour_end_Date = convertToDbFormat(currentEndDate);
  } else {
    tour_end_Date = convertToDbFormat(tour_end_Date_time);
  }
  
  
  const tour_tour_type = modalProps?.item?.tour_type;
  const [closeModalProps, setCloseModalProps] = useState({
    visible: false,
    title: "",
    key: "",
  });
  const [showEndDate, setShowEndDate] = useState(false);
  const [formStep, setFormStep] = useState(1);
  const [showsData, setShowsData] = useState(null);
  const [selectedTourStatus, setSelectedTourStatus] = useState("upcoming");
  const [selectedTourId, setSelectedTourId] = useState("");
  const [venueCapacity, setVenueCapacity] = useState("");
  const [venueName, setVenueName] = useState("");
  const [feeapparel, setfeeapparel] = useState(0);
  const [feeothers, setfeeothers] = useState(0);
  const [showDetails, setShowDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [addToursData, setAddToursData] = useState(null);
  const [toursData, setToursData] = useState(null);
      const handleShowEndDate = () => {
    setShowEndDate(!showEndDate);
  };
  //console.log(singleShowDetails && singleShowDetails, "sing");
  //console.log(venueList, "venueList");

  useEffect(() => {
    getTours(selectedTourStatus);
  }, [selectedTourStatus]);

  const getTours = async (selectedTourStatus) => {
    setIsLoading(true);
    const resp = await getApiCall(ApiUrl.toursList(selectedTourStatus));
    setIsLoading(false);
    if (resp.status && resp.data && resp.data.list) {
      setToursData(resp.data);
      localStorage.setItem("initalTourId", resp.data.list[0]?.id);
    } else {
      console.error("Failed to fetch tours data or empty list");
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getAddToursData();
  }, []);

  const getAddToursData = async () => {
    const resp = await getApiCall(ApiUrl.addToursData);
    if (resp.status) {
      setAddToursData(resp.data);
    }
  };

  const getFormattedToursData = (toursData) => {
    const formattedtoursList =
      toursData && toursData.list
        ? toursData.list.map((item) => {
            return {
              ...item,
              label: `${item.tour_name} `,
              value: `${item.id}`,
            };
          })
        : [];
    return formattedtoursList;
  };

  const handleToursDataChange = async (selectedTourId, toursData, values) => {
    console.log("Selected Tour ID:", selectedTourId);
    const selectedTour = toursData.list.find(
      (item) => `${item.id}` === selectedTourId
    );
    console.log("Tour Data:" + JSON.stringify(selectedTour));
    if (selectedTour) {
      setSelectedTourId(selectedTourId);
      //await getShowsList(selectedTourId, selectedTour.status);
      if (!selectedTour.start_date || !selectedTour.end_date) {
        toast.error(
          "This tour does not have a fixed date. Please choose another tour."
        );
        handleModalClose();
        return;
      }
      // If the tour date is fixed, proceed with other actions
      // addUpdateShow({ ...values, tour_id: selectedTourId });
      values.start_date = moment(selectedTour.start_date).format("DD/MMMM/YY");
      values.end_date = moment(selectedTour.start_date).format("DD/MMMM/YY");
      values.show_type = selectedTour.tour_type;
    }
  };
  const [tourId, setTourId] = useState(null);
  const getShowsList = async (tourId, selectedShowStatus) => {
    console.log("Fetching shows for tour ID:", tourId);
    const resp = await getApiCall(ApiUrl.showsList(tourId, selectedShowStatus));
    console.log("API Response:", resp);
    if (resp.status) {
      setIsLoading(false);
      setShowsData(resp.data);
    } else {
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setCloseModalProps({
      visible: false,
      title: "",
      key: "",
    });
  };

  const openAddShowModal = () => {
    // setShowDetails(null);
    setCloseModalProps({
      visible: true,
      title: "Add Show",
      key: "addShow",
      // item: item,
      // tourItemObj: tourItemObj, // Pass tourItemObj instead of tourId
      pageTitle: "shows",
    });
  };

  const handleVenueModalClose = () => {
    closeModal();
  };

  const addUpdateShow = async (payload) => {
    if (payload.venue_address) {
      payload.venue_name = payload.venue_address.split(",")[0];
      payload.venue_address = payload.venue_address.split(",")[1];
    }
    payload.city = payload.city;
    payload.tax_music = payload.tax_music;
    payload.tax_apparel = payload.tax_apparel;
    payload.tax_others = payload.tax_others;
    payload.state = payload.state;
    payload.fee_apparel = payload.fee_apparel;
    payload.fee_music = payload.fee_music;
    payload.fee_others = payload.fee_others;
    payload.promoter_name = payload.promoter_name;
    payload.promoter_phone = payload.promoter_phone;
    payload.promoter_email = payload.promoter_email;
    payload.note = payload.note;
    payload.start_date = convertToDbFormat(payload.start_date);
    if (payload.end_date) {
      payload.end_date = convertToDbFormat(payload.end_date);
    } else {
      payload.end_date = convertToDbFormat(payload.start_date);
    }
    if (selectedTourId) {
      payload.tour_id = selectedTourId;
    } else {
      console.error("selectedTourId is not defined");
      return;
    }

    const resp = await apiCall(
      //showDetails?.id ? ApiUrl.showEdit(showDetails.id) : ApiUrl.addShow,
      ApiUrl.addShow,
      payload
    );

    if (resp.status) {
      //closeModal();
      getShowsList(tourId, "left");
      // toast.success(
      //     `Show ${showDetails?.id ? "updated" : "added"} successfully`
      //   );
      // } else {
      //   toast.error(
      //     `Show not ${showDetails?.id ? "updated" : "added"}. Please try later`
      //   );
    }
  };

  const createVenueShow = async (pay) => {
    const payload = {
      venue_name: pay?.new_venue_name,
      venue_number: pay?.new_venue_number,
      unit: pay?.new_unit,
      street: pay?.new_street,
      city: pay?.new_city,
      state: pay?.new_state,
      zipcode: pay?.new_zipcode,
    };
    // const payload = {
    //   venue_name:"My New Venue",
    //   venue_number:"V124",
    //   unit:2000,
    //   street:"Main road",
    //   city:"Hyderabad",
    //   state:"TS",
    //   zipcode:"500099"
    //   }

    const resp = await apiCall(ApiUrl.createVenue, payload);
    if (resp.status) {
      getVenueList();
        //setProductList(resp.data.merch_list);
      
      const venuefind=`${payload.venue_name}${
        payload.street ? `, ${payload.street}` : ""
      }${
        payload.city ? `, ${payload.city}` : ""
      }${
        payload.state ? `, ${payload.state}` : ""
      }${
        payload.zipcode ? `, ${payload.zipcode}` : ""
      }`;
      //alert('Check: '+venuefind);
      handleVenueChange(venuefind,props.form,'create');
      toast.success("Venue created successfully");
      console.log(resp.data, "resp");
            return resp.data;
    } else {
      toast.error("Failed to create venue:", resp.message);
      console.error("Failed to create venue:", resp.message);
    }
  };

  const getFormattedVenueData = (venueList) => {
    // console.log(venueList)
    //console.log("venueList: " + JSON.stringify(venueList));
    const formattedVenueList =
      venueList &&
      venueList.length > 0 &&
      venueList.map((item) => {
        return {
          ...item,
          label: (
            <>
              {`${item.name}${
                item.addressLineOne ? `, ${item.addressLineOne}` : ""
              }`}{" "}
              {item.isVerified?
              <span className="text-primary">(Verified)</span>
              :
              <span className="text-danger">(Not Verified)</span>
              }
            </>
          ),
          value: `${item.name}${
            item.addressLineOne ? `, ${item.addressLineOne}` : ""
          }`,
          capacity: item.capacity,
        };
      });
    return formattedVenueList;
  };

  const handleVenueChange = (selectedVenue, form, selectType='normal') => {
        if(selectType == 'create'){
      //getVenueList();
      console.log(selectedVenue);
    }
    //alert(selectedVenue);
    const venue = venueList.find(
      (item) =>
        `${item.name}${
          item.addressLineOne ? `, ${item.addressLineOne}` : ""
        }` === selectedVenue
    );
    //alert(selectedVenue);
    // const venue = venueList.find(
    //   (item) =>
    //     `${item.name}` === selectedVenue
    // );
        console.log("Selected Venue:"+JSON.stringify(venue));
    if (venue) {
      setVenueCapacity(venue.capacity);
      setVenueName(venue.name);
      if (form) {
        form.setFieldValue("show_capacity", venue.capacity);
        form.setFieldValue("venue_name", venue.name);
        form.submitForm();
      }
    } else {
      setVenueCapacity(0);
      if (form) {
        form.setFieldValue("show_capacity", 0);
        form.submitForm();
      }
    }
  };
  const handleChange = (e,form) => {
    const fee_others = e.target.value;
    //alert(fee_others);
    setfeeapparel(fee_others);
    setfeeothers(fee_others);
    console.log("Test:"+JSON.stringify(form));
    if (form) {
     //form.setFieldValue("fee_apparel", fee_others);
    // form.setFieldValue("fee_others", fee_others);
     //form.submitForm();
    }
  }
  // const handleChange = (selectedVenue, form) => {
  //   // const { name, value } = sele.target;
  //   // alert(name);
  //   // alert(value);
  //   alert('dsds');
  //   // setFormValues({
  //   //   ...formValues,
  //   //   [name]: value
  //   // });
  // };
  const handleCreateVenue = (formValues) => {
    createVenueShow(formValues);
    //getVenueList();
    closeModal();
   //console.log(JSON.stringify(formValues));
    // const venuefind=`${formValues.new_venue_name}${
    //   formValues.new_street ? `, ${formValues.new_street}` : ""
    // }${
    //   formValues.new_city ? `, ${formValues.new_city}` : ""
    // }${
    //   formValues.new_state ? `, ${formValues.new_state}` : ""
    // }${
    //   formValues.new_zipcode ? `, ${formValues.new_zipcode}` : ""
    // }`;
    // //alert('Check: '+venuefind);
    // handleVenueChange(venuefind,props.form,'create');
    formValues.new_venue_name = "";
    formValues.new_venue_number = "";
    formValues.new_unit = "";
    formValues.new_zipcode = "";
    formValues.new_street = "";
    formValues.new_state = "";
    formValues.new_city = "";
  };

  useEffect(() => {
    getToursList();
  },[shouldHitAPI]);
  const getToursList = async () => {
    const tourId = 65;
      const resp = await getApiCall(ApiUrl.toursList("upcoming"));
      if (resp.status && resp.data.list.length>0
      ) {
        setToursList(resp.data.list);
        setSelectedTour(tourId);
        console.log('My Tour:'+JSON.stringify(tourItemObj));
        if (tourItemObj === "") {
          localStorage.setItem("initalTourId", resp.data.list[0]?.id);
          localStorage.setItem("tourItemObj", JSON.stringify(resp.data.list[0]));
          setSelectedTour(resp.data.list[0]);
        } else {
          setSelectedTour(tourItemObj);
        }
      }
   
  };
  const [showToursDropdown, setShowToursDropdown] = useState(false);
  const [toursList, setToursList] = useState(null);
  const [selectedTour, setSelectedTour] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const location = useLocation();
  const tourItem = typeof location?.state?.item === 'undefined' || location?.state.item === ''? JSON.parse(localStorage.getItem("tourItemObj")) : location?.state.item;
  console.log("Secondory header:"+JSON.stringify(tourItemObj));
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowToursDropdown(false);
      }
    };
 
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const navigateToShows = (item) => {
    setShowToursDropdown(false);
    setSelectedTour(item);
    const tourId = item.id;
    navigate("/shows", { state: { tourId: tourId, item } });
    setSearchInput("");
  };
 
  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };
 
  const filteredToursList = toursList
    ? toursList.filter((item) =>
      item.tour_name.toLowerCase().includes(searchInput.toLowerCase())
    )
    : [];

  const renderStep1Form = (errors, touched, values) => {
    return (
      <div className="flex flex-col mt-5">
        <div className="flex flex-row gap-4">
          <div className="h-16">
            <CustomDatepicker
              name="start_date"
              className="p-2.5 w-50 border-placeholderColor"
              placeholder="Start date"
              startDate={tour_Start_Date}
              endDate={tour_end_Date}
            />
            {touched.start_date && errors.start_date ? (
              <span className="text-danger font-pop text-xs my-1">
                {errors.start_date}
              </span>
            ) : null}
          </div>
          {showEndDate && (
            <div className="h-16">
              <CustomDatepicker
                name="end_date"
                className="p-2.5 w-50 border-placeholderColor"
                placeholder="End date"
                startDate={values.start_date}
                endDate={tour_end_Date}
              />
              {touched.end_date && errors.end_date ? (
                <span className="text-danger font-pop text-xs my-1">
                  {errors.end_date}
                </span>
              ) : null}
            </div>
          )}
          <div
            className="flex justify-center items-center border border-placeholderColor h-9 w-9 rounded-lg cursor-pointer"
            onClick={handleShowEndDate}
          >
            {showEndDate ? (
              <img src={AppImages.cancelBlack} className="w-3 h-3"/>
            ) : (
              <img src={AppImages.plus} className="w-3 h-3"/>
            )}
          </div>
          <div className="flex flex-row gap-4 items-center relative" style={{height:"60px"}} ref={dropdownRef}>
  <div className="relative w-44 h-16 cursor-pointer" onClick={() => setShowToursDropdown(!showToursDropdown)}>
    <input
      type="text"
      readOnly
      value={
        selectedTour?.tour_name ??
        (toursList && toursList.length > 0 ? toursList[0].tour_name : "")
      }
      placeholder=" "
      className="text-xs p-2.5 w-full border rounded-lg border-placeholderColor cursor-pointer focus:outline-none focus:ring-1 focus:ring-primary"
    />
    <label className="absolute top-1 left-3 px-1 text-xs text-gray-500 bg-white transform -translate-y-1/2 pointer-events-none">
      {selectedTour ? "Selected Tour" : "Select Tour"}
    </label>
    <img
      src="/chevron_down.png"
      className="absolute top-1/4 mt-1.5 right-3 w-4 h-4 object-contain transform -translate-y-1/2 pointer-events-none"
    />
  </div>

  {showToursDropdown && (
    <div className="absolute top-full -mt-2 w-44 bg-white border border-primary rounded-md shadow-lg z-50">
      <div className="p-1">
        <SearchInput
          searchInput={searchInput}
          handleSearchInputChange={handleSearchInputChange}
          className="w-full p-1 border border-gray-300 rounded focus:outline-none"
        />
      </div>
                <ul className="max-h-44 overflow-y-scroll hide-scrollbar">
                <li
                    key={'+Add Tour'}
                    className="hover:bg-primary hover:text-white cursor-pointer font-pop text-xs text-primary p-2"
                    onClick={sendDataToParent}
          >
            <p>{"+ Add Tour"}</p>
          </li>
        {filteredToursList.map((item) => (
          <li
            key={item.id}
            className="hover:bg-primary hover:text-white cursor-pointer font-pop text-xs text-primary p-2"
            onClick={() => {
              navigateToShows(item);
            }}
          >
            <p>{item.tour_name}</p>
          </li>
        ))}
      </ul>
    </div>
  )}
</div>
</div>


        <div className="h-16 relative">
          <div className="flex flex-row gap-4 items-center">
            <div className="h-16">
              <TextInput
                type="text"
                name="city"
                placeholder="City"
                rightIcon={null}
                className="p-2.5 w-60 border-placeholderColor"
              />
            </div>
            <div className="h-16">
              <TextInput
                type="text"
                name="state"
                placeholder="State"
                rightIcon={null}
                className="p-2.5 w-60 border-placeholderColor"
              />
              {/* <CustomSelectPicker
                isMulti={false}
                label={""}
                placeHolder={"State"}
                data={getFormattedVenueData(venueList)}
                name={"venue_address"}
                type="text"
                className="w-96 px-2.5 border-placeholderColor"
                handleCallback={(selectedVenue) =>
                  handleVenueChange(selectedVenue, props.form)
                }
                allowCreate={true}
              /> */}
              {/* <span className="text-danger text-sm font-pop text-xs">
                {errors.venue_address &&
                  touched.venue_address &&
                  errors.venue_address}
              </span> */}
            </div>
            <div className="h-16">
              {/* {modalProps.pageTitle === "tours" ? (
                <CustomSelectPicker
                  isMulti={false}
                  label={""}
                  type="hidden"
                  placeHolder={"Select Tour"}
                  data={getFormattedToursData(toursData)}
                  name={"tour_id"}
                  className="px-2.5 w-72 border-placeholderColor"
                  handleCallback={(selectedTourId) =>
                    handleToursDataChange(selectedTourId, toursData, values)
                  }
                  errorMessage={
                    errors.tour_id && touched.tour_id && errors.tour_id
                  }
                  // onClick="selectedTour.start_date"
                />
              ) : null} */}
              <span className="text-danger text-sm font-pop text-xs">
                {errors.tour_id && touched.tour_id && errors.tour_id}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-4 items-center">
          <TextInput
            type="number"
            name="no_of_shows"
            placeholder="No. of Shows"
            rightIcon={null}
            className="p-2.5 w-44 border-placeholderColor"
          />
          <div className="h-16" style={{ zIndex: 10 }}>
            <CustomSelectPicker
              isMulti={false}
              placeHolder={"Show Type"}
              data={showTypes}
              name={"show_type"}
              showType={tour_tour_type}
              className="w-60 px-2.5 border-placeholderColor"
            />
            <span className="text-danger text-sm font-pop text-xs">
              {errors.show_type && touched.show_type && errors.show_type}
            </span>
          </div>
        </div>
        <div className="flex flex-row gap-2 justify-between">
          {/* <div>
            <p className="text-md font-pop text-primary">Tax Method</p>
            <CustomRadio data={taxMethods} name={"tax_method"} label={""} />
            <span className="text-danger text-sm font-pop text-xs">
              {errors.tax_method && touched.tax_method && errors.tax_method}
            </span>
          </div> */}
          <div className="flex flex-row gap-4 items-center">
            <TextInput
              type="number"
              name="fee_apparel"
              placeholder="Apparel Fee%"
              rightIcon={null}
              className="p-2.5 w-40 border-placeholderColor"
              //value={feeapparel}
              onChange={(e) => handleChange(e, values)}
              onBlur={(e) => handleChange(e, values)}
            />
            <TextInput
              type="number"
              name="fee_music"
              placeholder="Music Fee %"
              rightIcon={null}
              className="p-2.5 w-40 border-placeholderColor"
            />
            <TextInput
              type="number"
              name="fee_others"
              placeholder="Other Fee %"
              rightIcon={null}
              value={feeothers}
              className="p-2.5 w-40 border-placeholderColor"
                          />
          </div>
        </div>
        <div className="flex flex-row gap-2 mb-4">
          <CustomModal
            handleModalClose={closeModal}
            modalProps={closeModalProps}
            className={`overflow-y-scroll w-full rounded-2xl shadow-xl h-[300px]
              }`}
          >
            <div className="mb-4">
              <div className="w-full justify-between flex justify-end">
                <h1 className="text-primary font-pop text-xl font-medium">
                  Create Venue
                </h1>
                <div className=" flex flex-row justify-between items-center gap-20">
                  <div className="flex flex-row gap-4">
                    <button
                      type="button"
                      className="flex justify-center items-center  bg-grey h-8 w-8 rounded-lg shadow-lg"
                      onClick={handleVenueModalClose}
                    >
                      <img src={AppImages.cancelBlack} className="w-3 h-3" />
                    </button>
                    <button
                      type="submit"
                      className="flex justify-center items-center  bg-secondary h-8 w-8 rounded-lg shadow-lg"
                      onClick={() => handleCreateVenue(values)}
                    >
                      <img src={AppImages.checkIcon} className="w-3 h-3" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="w-full border mt-4"></div>
            </div>
            <div className="flex flex-row gap-2">
              <TextInput
                type="text"
                name="new_venue_name"
                placeholder="Venue Name"
                rightIcon={null}
                className="p-2.5 w-48 border-placeholderColor"
              />
              <TextInput
                type="text"
                name="new_venue_number"
                placeholder="Venue Number"
                rightIcon={null}
                className="p-2.5 w-48 border-placeholderColor"
              />
              <TextInput
                type="text"
                name="new_unit"
                placeholder="Unit"
                rightIcon={null}
                className="p-2.5 w-48 border-placeholderColor"
              />
            </div>
            <div className="flex flex-row gap-2">
              <TextInput
                type="text"
                name="new_street"
                placeholder="Street"
                rightIcon={null}
                className="p-2.5 w-60 border-placeholderColor"
              />
              <TextInput
                type="text"
                name="new_city"
                placeholder="City"
                rightIcon={null}
                className="p-2.5 w-60 border-placeholderColor"
              />
            </div>
            <div className="flex flex-row gap-2">
              <TextInput
                type="text"
                name="new_state"
                placeholder="State"
                rightIcon={null}
                className="p-2.5 w-60 border-placeholderColor"
              />
              <TextInput
                type="text"
                name="new_zipcode"
                placeholder="Zipcode"
                rightIcon={null}
                className="p-2.5 w-60 border-placeholderColor"
              />
            </div>
          </CustomModal>
          <div style={{ zIndex: 10 }}>
            <CustomSelectPicker
              isMulti={false}
              label={""}
              placeHolder={"Search Venue / City"}
              data={getFormattedVenueData(venueList)}
              name={"venue_address"}
              type="text"
              // venueAddress = {newVenueName}
              className="w-120 px-2.5 border-placeholderColor"
              handleCallback={(selectedVenue) =>
                handleVenueChange(selectedVenue, props.form)
              }
              // defaultValue={{ label: "Default Venue", value: "default_venue_id" }}
              allowCreate={true}
            />
          </div>
          <span className="text-danger text-sm font-pop text-xs">
            {errors.venue_address &&
              touched.venue_address &&
              errors.venue_address}
          </span>
          {/* {venueName && <div className="h-16 w-24 flex flex-col items-center">
              <img src={AppImages.primaryCheckIcon} className="w-3 h-3 object-contain mt-1" />
              <p className="text-primary">Verified</p>
            </div>} */}
            <div
            className="flex justify-center items-center w-9 h-9 rounded-md cursor-pointer"
            onClick={openAddShowModal}
          >
            <img src={AppImages.plus} className="w-5 h-5 object-contain mt-1" />
          </div>
        </div>
        <div className="h-24">
          <CustomTextArea
            name="venue_address_readable"
            placeholder="Venue address"
            rows={3}
            className="	p-2.5 border-placeholderColor"
            disabled={"disabled"}
            value={values.venue_address && values.venue_address.split(",")[1]}
          />
        </div>
        <div className="flex flex-row gap-4 items-center">
          <TextInput
            type="text"
            name="venue_rep_name"
            placeholder="Venue Representative name"
            rightIcon={null}
            className="p-2.5 w-56 border-placeholderColor"
          />
          <TextInput
            type="email"
            name="venue_rep_email"
            placeholder="Venue Representative Email"
            rightIcon={null}
            className="p-2.5 w-56 border-placeholderColor"
          />
          <TextInput
            type="text"
            name="venue_rep_phone"
            placeholder="Venue Representative Phone"
            rightIcon={null}
            className="p-2.5 w-56 border-placeholderColor"
          />
        </div>
        <div className="flex flex-row gap-4 items-center">
          <TextInput
            type="number"
            name="tax_apparel"
            placeholder="Apparel Tax Info %"
            rightIcon={null}
            className="p-2.5 w-36 border-placeholderColor"
          />
          <TextInput
            type="number"
            name="tax_music"
            placeholder="Music Tax Info %"
            rightIcon={null}
            className="p-2.5 w-36 border-placeholderColor"
          />
          <TextInput
            type="number"
            name="tax_others"
            placeholder="Other Tax info %"
            className="p-2.5 w-36 border-placeholderColor"
          />
        </div>
        <div className="flex flex-row gap-2 items-center">
          <TextInput
            type="text"
            name="promoter_name"
            placeholder="Promoter Name"
            rightIcon={null}
            className="p-2.5 w-48 border-placeholderColor"
          />
          <TextInput
            type="text"
            name="promoter_phone"
            placeholder="Promoter Phone"
            rightIcon={null}
            className="p-2.5 w-48 border-placeholderColor"
          />
          <TextInput
            type="email"
            name="promoter_email"
            placeholder="Promoter Email"
            rightIcon={null}
            className="p-2.5 w-48 border-placeholderColor"
          />
        </div>
        <div className="h-24">
          <CustomTextArea
            name="note"
            placeholder="Note"
            rows={4}
            className="	p-2.5 border-placeholderColor"
          />
          <span className="text-danger text-sm font-pop text-xs">
            {errors.note && touched.note && errors.note}
          </span>
        </div>
        {/* <div className="flex flex-row gap-2 items-center">
          <TextInput
            type="text"
            name="country_name"
            placeholder="Country"
            rightIcon={null}
            className="p-2.5 w-52 border-placeholderColor"
          />

          <TextInput
            type="text"
            name="zipcode_name"
            placeholder="Zipcode"
            rightIcon={null}
            className="p-2.5 w-52 border-placeholderColor"
          />
        </div> */}
      </div>
    );
  };

  const renderStep2Form = (errors, touched, values) => {
    return (
      <div className="flex flex-col mt-4">
        <div className="flex flex-row gap-2 mb-4">
          <CustomModal
            handleModalClose={closeModal}
            modalProps={closeModalProps}
            className={`overflow-y-scroll w-full rounded-2xl shadow-xl h-[300px]
              }`}
          >
            <div className="mb-4">
              <div className="w-full justify-between flex justify-end">
                <h1 className="text-primary font-pop text-xl font-medium">
                  Create Venue
                </h1>
                <div className=" flex flex-row justify-between items-center gap-20">
                  <div className="flex flex-row gap-4">
                    <button
                      type="button"
                      className="flex justify-center items-center  bg-grey h-8 w-8 rounded-lg shadow-lg"
                      onClick={handleVenueModalClose}
                    >
                      <img src={AppImages.cancelBlack} className="w-3 h-3" />
                    </button>
                    <button
                      type="submit"
                      className="flex justify-center items-center  bg-secondary h-8 w-8 rounded-lg shadow-lg"
                      onClick={() => handleCreateVenue(values)}
                    >
                      <img src={AppImages.checkIcon} className="w-3 h-3" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="w-full border mt-4"></div>
            </div>
            <div className="flex flex-row gap-2">
              <TextInput
                type="text"
                name="new_venue_name"
                placeholder="Venue Name"
                rightIcon={null}
                className="p-2.5 w-48 border-placeholderColor"
              />
              <TextInput
                type="text"
                name="new_venue_number"
                placeholder="Venue Number"
                rightIcon={null}
                className="p-2.5 w-48 border-placeholderColor"
              />
              <TextInput
                type="text"
                name="new_unit"
                placeholder="Unit"
                rightIcon={null}
                className="p-2.5 w-48 border-placeholderColor"
              />
            </div>
            <div className="flex flex-row gap-2">
              <TextInput
                type="text"
                name="new_street"
                placeholder="Street"
                rightIcon={null}
                className="p-2.5 w-60 border-placeholderColor"
              />
              <TextInput
                type="text"
                name="new_city"
                placeholder="City"
                rightIcon={null}
                className="p-2.5 w-60 border-placeholderColor"
              />
            </div>
            <div className="flex flex-row gap-2">
              <TextInput
                type="text"
                name="new_state"
                placeholder="State"
                rightIcon={null}
                className="p-2.5 w-60 border-placeholderColor"
              />
              <TextInput
                type="text"
                name="new_zipcode"
                placeholder="Zipcode"
                rightIcon={null}
                className="p-2.5 w-60 border-placeholderColor"
              />
            </div>
          </CustomModal>
          <div style={{ zIndex: 10 }}>
            <CustomSelectPicker
              isMulti={false}
              label={""}
              placeHolder={"Search Venue / City"}
              data={getFormattedVenueData(venueList)}
              name={"venue_address"}
              type="text"
              // venueAddress = {newVenueName}
              className="w-120 px-2.5 border-placeholderColor"
              handleCallback={(selectedVenue) =>
                handleVenueChange(selectedVenue, props.form)
              }
              // defaultValue={{ label: "Default Venue", value: "default_venue_id" }}
              allowCreate={true}
            />
          </div>
          <span className="text-danger text-sm font-pop text-xs">
            {errors.venue_address &&
              touched.venue_address &&
              errors.venue_address}
          </span>
          {/* {venueName && <div className="h-16 w-24 flex flex-col items-center">
              <img src={AppImages.primaryCheckIcon} className="w-3 h-3 object-contain mt-1" />
              <p className="text-primary">Verified</p>
            </div>} */}
            <div
            className="flex justify-center items-center w-9 h-9 rounded-md cursor-pointer"
            onClick={openAddShowModal}
          >
            <img src={AppImages.plus} className="w-5 h-5 object-contain mt-1" />
          </div>
        </div>
        <div className="h-24">
          <CustomTextArea
            name="venue_address_readable"
            placeholder="Venue address"
            rows={3}
            className="	p-2.5 border-placeholderColor"
            disabled={"disabled"}
            value={values.venue_address && values.venue_address.split(",")[1]}
          />
        </div>
        <div className="flex flex-row gap-4 items-center">
          <TextInput
            type="text"
            name="venue_rep_name"
            placeholder="Venue Representative name"
            rightIcon={null}
            className="p-2.5 w-56 border-placeholderColor"
          />
          <TextInput
            type="email"
            name="venue_rep_email"
            placeholder="Venue Representative Email"
            rightIcon={null}
            className="p-2.5 w-56 border-placeholderColor"
          />
          <TextInput
            type="text"
            name="venue_rep_phone"
            placeholder="Venue Representative Phone"
            rightIcon={null}
            className="p-2.5 w-56 border-placeholderColor"
          />
        </div>

        <div className="flex flex-row gap-4 items-center">
          <TextInput
            type="number"
            name="tax_apparel"
            placeholder="Apparel Tax Info %"
            rightIcon={null}
            className="p-2.5 w-36 border-placeholderColor"
          />
          <TextInput
            type="number"
            name="tax_music"
            placeholder="Music Tax Info %"
            rightIcon={null}
            className="p-2.5 w-36 border-placeholderColor"
          />
          <TextInput
            type="number"
            name="tax_others"
            placeholder="Other Tax info %"
            className="p-2.5 w-36 border-placeholderColor"
          />
        </div>
      </div>
    );
  };

  const getInitialValues = () => {
    let initialValues;
    if (modalProps.key === "addShow" || modalProps.key === "editShow") {
      initialValues = {
        tour_id: singleShowDetails ? singleShowDetails.tour_id : "",
        start_date: singleShowDetails
          ? singleShowDetails.start_date
          : moment(tour_Start_Date).format("DD/MMMM/YY"),
        end_date: singleShowDetails
          ? singleShowDetails.end_date
          : moment(tour_Start_Date).format("DD/MMMM/YY"),
        no_of_shows: singleShowDetails ? singleShowDetails.no_of_shows : "1",
        venue_address: singleShowDetails
           ? `${singleShowDetails.venue_name}, ${singleShowDetails.venue_address}`
          : "" ,
        show_type: singleShowDetails
          ? singleShowDetails.show_type
          : tour_tour_type,
        venue_name: singleShowDetails ? singleShowDetails.venue_name : "",
        show_capacity: singleShowDetails ? singleShowDetails.show_capacity : "",
        city: singleShowDetails ? singleShowDetails.city : "",
        state: singleShowDetails ? singleShowDetails.state : "",
        // country_name: singleShowDetails ? singleShowDetails.country_name : "",
        // zipcode_name: singleShowDetails ? singleShowDetails.zipcode_name : "",
        tax_method: singleShowDetails
          ? singleShowDetails.tax_method
          : "inclusive",
        promoter_name: singleShowDetails ? singleShowDetails.promoter_name : "",
        promoter_phone: singleShowDetails
          ? singleShowDetails.promoter_phone
          : "",
        promoter_email: singleShowDetails
          ? singleShowDetails.promoter_email
          : "",
        tax_apparel: singleShowDetails ? singleShowDetails.tax_apparel : "0",
        tax_others: singleShowDetails ? singleShowDetails.tax_others : "0",
        tax_music: singleShowDetails ? singleShowDetails.tax_music : "0",
        venue_rep_name: singleShowDetails
          ? singleShowDetails.venue_rep_name
          : "",
        venue_rep_email: singleShowDetails
          ? singleShowDetails.venue_rep_email
          : "",
        venue_rep_phone: singleShowDetails
          ? singleShowDetails.venue_rep_phone
          : "",
        note: singleShowDetails ? singleShowDetails.note : "",
        total_tickets: singleShowDetails ? singleShowDetails.total_tickets : "",
        fee_apparel: singleShowDetails ? singleShowDetails.fee_apparel : "0",
        fee_others: singleShowDetails ? singleShowDetails.fee_others : "0",
        fee_music: singleShowDetails ? singleShowDetails.fee_music : "0",
        new_venue_name: singleShowDetails
        ? singleShowDetails.new_venue_name
        : "",
        new_venue_number: singleShowDetails
        ? singleShowDetails.new_venue_number
        : "",
        new_unit: singleShowDetails ? singleShowDetails.new_unit : "",
        new_city: singleShowDetails ? singleShowDetails.new_city : "",
        new_state: singleShowDetails ? singleShowDetails.new_state : "",
        new_zipcode: singleShowDetails ? singleShowDetails.new_zipcode : "",
        new_street: singleShowDetails ? singleShowDetails.new_street : "",

        concession_company: singleShowDetails
          ? singleShowDetails.concession_company
          : "",
      };
    } else if (modalProps.key === "postponeShow") {
      initialValues = {
        start_date: singleShowDetails
          ? singleShowDetails.start_date
          : moment(tour_Start_Date).format("DD/MMMM/YY"),
        end_date: singleShowDetails
          ? singleShowDetails.end_date
          : moment(tour_end_Date).format("DD/MMMM/YY"),
      };
    }
    return initialValues;
  };

  const renderPostponeForm = (errors, touched, values) => {
    return (
      <div className="flex flex-col mt-5">
        <div className="h-16">
          <div className="flex flex-row gap-4">
            <div className="h-16">
              <CustomDatepicker
                name="start_date"
                className="p-2.5 w-60 border-placeholderColor"
                placeholder="Start date"
                hanldeSetModalHeight={handleSetAddExpandModal}
              />
              {touched.start_date && errors.start_date ? (
                <span className="text-danger font-pop text-xs my-1">
                  {errors.start_date}
                </span>
              ) : null}
            </div>
            {showEndDate && (
              <div className="h-16">
                <CustomDatepicker
                  name="end_date"
                  className="p-2.5 w-60 border-placeholderColor"
                  placeholder="End date"
                  startDate={values.start_date}
                  hanldeSetModalHeight={handleSetAddExpandModal}
                />

                {touched.end_date && errors.end_date ? (
                  <span className="text-danger font-pop text-xs my-1">
                    {errors.end_date}
                  </span>
                ) : null}
              </div>
            )}
            <div
              className="flex justify-center items-center border border-placeholderColor h-9 w-9 rounded-lg cursor-pointer"
              onClick={handleShowEndDate}
            >
              {showEndDate ? (
                <img src={AppImages.cancelBlack} className="w-3 h-3" />
              ) : (
                <img src={AppImages.plus} className="w-3 h-3" />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderPaginationView = () => {
    return (
      <div className="flex flex-row gap-4 mt-6 justify-center items-center">
        {/* <div
          className={`${
            formStep === 1 ? "bg-primary" : "bg-white"
          } h-7 w-7 flex justify-center items-center border rounded-md cursor-pointer`}
          onClick={() => setFormStep(1)}
        >
          <p
            className={`font-pop text-xs ${
              formStep === 1 ? "text-white" : "text-primary"
            }`}
          >
            {" "}
            1
          </p>
        </div> */}
        <p className="text-primary font-pop"></p>
        {/* <div
          className={`${
            formStep === 2 ? "bg-primary" : "bg-white"
          } h-7 w-7 flex justify-center items-center border rounded-md cursor-pointer`}
          onClick={() => setFormStep(2)}
        >
          <p
            className={`font-pop text-xs ${
              formStep === 2 ? "text-white" : "text-primary"
            }`}
          >
            {" "}
            2
          </p>
        </div> */}
      </div>
    );
  };

  const renderManageShowIcons = () => {
    return (
      <div className="flex flex-row gap-4">
        <div className="flex justify-center items-center border border-secondary h-8 w-8 rounded-lg">
          <img src={AppImages.circlePlus} className="w-4 h-4" />
        </div>
        <div className="flex justify-center items-center border border-secondary h-8 w-8 rounded-lg">
          <img src={AppImages.upDown} className="w-4 h-4" />
        </div>
        <div className="flex justify-center items-center border border-secondary h-8 w-8 rounded-lg">
          <img src={AppImages.calendarStar} className="w-4 h-4" />
        </div>
        <div className="flex justify-center items-center border border-secondary h-8 w-8 rounded-lg">
          <img src={AppImages.calendarCheck} className="w-4 h-4" />
        </div>
        <div className="flex justify-center items-center border border-secondary h-8 w-8 rounded-lg">
          <img src={AppImages.calendari} className="w-4 h-4" />
        </div>
      </div>
    );
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      validationSchema={
        modalProps.key === "addShow" || modalProps.key === "editShow"
          ? ShowsSchema
          : PostponeShowsSchema
      }
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        const formValues = {
          ...values,
          venue_name: venueName,
          show_capacity: venueCapacity,
          tour_id: selectedTourId,
          fee_apparel: feeapparel,
          fee_others: feeothers
        };
        handleSubmitForm(formValues);
        console.log("post data", formValues);
        console.log("Selected Tour ID:", selectedTourId);
      }}
    >
      {({
        handleSubmit,
        isSubmitting,
        errors,
        touched,
        values,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit} className="m-3 form-overflow">
          <div className="p-4">
            <div className=" flex flex-row justify-between items-center gap-20">
              <div className="flex flex-col">
                <p className="font-pop text-xl font-medium text-primary">
                  {modalProps.title}
                </p>
              </div>
              {/*modalProps.key !== "postponeShow" && renderManageShowIcons()*/}
              <div className="flex flex-row gap-4">
                <button
                  type="button"
                  className="flex justify-center items-center  bg-grey h-8 w-8 rounded-lg shadow-lg"
                  onClick={handleModalClose}
                >
                  <img src={AppImages.cancelBlack} className="w-3 h-3" />
                </button>
                {/* {(formStep === 2 || modalProps.key === "postponeShow") && ( */}
                <button
                  type="submit"
                  className="flex justify-center items-center  bg-secondary h-8 w-8 rounded-lg shadow-lg"
                >
                  <img src={AppImages.checkIcon} className="w-3 h-3" />
                </button>
                {/* )} */}
              </div>
            </div>
            {modalProps.key !== "postponeShow" && (
              <p className="font-pop text-xs  text-placeholderColor">
                {/* Add Show */}
              </p>
            )}
          </div>
          <div className="border mx-4" />
          <div className="mx-4">
            {modalProps.key === "addShow" || modalProps.key === "editShow" ? (
              <>
                {renderStep1Form(errors, touched, values)}
                {/* {formStep === 2 && renderStep2Form(errors, touched, values)} */}
              </>
            ) : (
              renderPostponeForm(errors, touched, values)
            )}
            {/* {modalProps.key !== "postponeShow" && renderPaginationView()} */}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ShowsForm;
