import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { AppImages } from "../../../../Constants/Images";
import { ToursSchema } from "../../../../Utilites/Validation";
import TextInput from "../../../../Components/TextInput";
import CustomSelectPicker from "../../../../Components/CustomSelect";
import CustomDatepicker from "../../../../Components/CustomDatePicker";
import { getApiCall ,apiCall} from "../../../..//Utilites/Api";
import { ApiUrl } from "../../../..//Constants/Api";
import moment from "moment";
import ModalCustomTrailers from "../../ModalCustomTrailers";
import { toast } from "react-toastify";
const ToursForm = (props) => {
  const {
    handleModalClose,
    venueList,
    handleAddOrUpdateTour,
    addToursData,
    handleSetAddExpandModal,
    singleTourDetails,
    modalTitle,
  } = props;
  const [productsList, setProductList] = useState([]);
  const [TrailersData, setTrailersData] = useState([]);

  const getTrailers = async () => {
    const resp = await getApiCall(ApiUrl.TrailersList());
    if (resp.status) {
      setIsLoading(false);
      setTrailersData(resp.data);
      // If selected trailer exists in localStorage, set it, else select the first trailer
      const selectedTrailerFromStorage = JSON.parse(localStorage.getItem("selectedTrailer"));
      if (selectedTrailerFromStorage && resp.data.some(trailer => trailer.id === selectedTrailerFromStorage.id)) {
        setSelectedTrailer(selectedTrailerFromStorage);
      } else {
        setSelectedTrailer(resp.data[0] || null);
      }
      getMerchList(selectedTrailerFromStorage || resp.data[0]);
    } else {
      setIsLoading(false);
    }
  };

  const openAddTrailerModal = () => {
    setTrailerDetails(null);
    setModalProps({
      visible: true,
      title: "Add Trailer",
      key: "addTrailer",
    });
  };
  const getFormttedTourTypeData = (tourTypes) => {
    const formattedTourTypes =
      tourTypes &&
      tourTypes.length > 0 &&
      tourTypes.map((item) => {
        return {
          label: item,
          value: item,
        };
      });
    return formattedTourTypes;
  };

  const getFormttedCurrencyData = (currencyTypes) => {
    const formattedCurrency =
      currencyTypes &&
      currencyTypes.length > 0 &&
      currencyTypes.map((item) => {
        return {
          label: `${item.code} ${item.symbol}`,
          value: `${item.code} ${item.symbol}`,
        };
      });
    return formattedCurrency;
  };
  const getMerchList = async (trailerItem) => {
    const resp = await getApiCall(ApiUrl.merchList("trailer", trailerItem?.id));
    if (resp.status) {
      setProductList(resp.data.merch_list);
    }
  };
  const VendTypes = [
    { id: 1, value: "one", label: "Off gross" },
    { id: 2, value: "two", label: "Off gross after cc fee" },
    { id: 3, value: "three", label: "Off gross after taxes" },
    { id: 4, value: "four", label: "Off gross after hall fee" },
    { id: 5, value: "five", label: "Off gross after hall fee and cc fee" },
    { id: 6, value: "six", label: "Off gross after hall fee and taxes" },
    {
      id: 7,
      value: "seven",
      label: "Off gross after hall fee, taxes and cc fee",
    },
  ];

  // const formatPercentage = (value) => {
  //   return value && value !== "" ? `${value}%` : "";
  // };

  // // Helper function to parse the percentage value
  // const parsePercentage = (value) => {
  //   return value && value !== "" ? value.replace("%", "") : "";
  // };
  const [trailersList, setTrailersList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [trailersaction, setTrailersaction] = useState(false);
  const [trailerapiaction, settrailerapiaction] = useState(false);

  useEffect(() => {
    const fetchTrailersList = async () => {
      const resp = await getApiCall(ApiUrl.TrailersList());
      if (resp.status && resp.data) {
        setTrailersList([
          { value: "_@add Trailer", label: "+ Add Trailer" },
          ...resp.data.map((item) => ({
            value: item.id,
            label: item.trailer_name,
          })),
        ]);
      }
      setLoading(false);
    };
  
    fetchTrailersList();
  }, [trailerapiaction]);

  const getPreselectedTrailers = () => {
    if (!singleTourDetails || !singleTourDetails.trailers) return [];
    return singleTourDetails.trailers.map((t) => t.id);
  };
  
  const [showModal, setShowModal] = useState(true);
  const [merchDetails, setmerchDetails] = useState(null);
  const [selectedTrailer, setSelectedTrailer] = useState(null);
  const [TrailerDetails, setTrailerDetails] = useState(null);


  useEffect(() => {
    setIsLoading(true);
    getTrailers();
  }, [trailerapiaction]);

  const closeModal = () => {
    setShowModal(false);
    setTrailerDetails(null);
    setModalProps({
        visible: false,
        title: "",
        key: "",
    });
};

  const [modalProps, setModalProps] = useState({
    visible: false,
    title: "",
    key: "",
  });

  const updateMerchStocks = async (payload, merchSizes) => {
    const updatedPayload = merchSizes.map((item) => {
      return {
        ...item,
        stock_type: payload.stock_type,
        stock_id: payload.stock_id,
        merch_child_id: item.id,
      };
    });
    const resp = await apiCall(ApiUrl.addMerchStock(), updatedPayload);
    if (resp.status) {
      closeModal();
      getMerchList(selectedTrailer);
      toast.success(`Merch details updated successfully`);
    } else {
      toast.error(`Merch details not updated. Please try later`);
    }
  };

  const updateMerchShipment = async (payload) => {
    const { inventory_type, inventory_data } = payload;
    const updatedPayload = inventory_data.map((item) => ({
      merch_id: item.merch_id,
      merch_child_id: item.merch_child_id,
      from_stock_type: item.from_stock_type,
      from_stock_id: item.from_stock_id,
      to_stock_type: item.to_stock_type,
      to_stock_id: item.to_stock_id,
      quantity: item.quantity,
    }));

    const finalPayload = {
      inventory_type,
      inventory_data: updatedPayload,
    };

    try {
      if ((finalPayload.inventory_data[0].from_stock_type != finalPayload.inventory_data[0].to_stock_type) || (finalPayload.inventory_data[0].from_stock_id != finalPayload.inventory_data[0].to_stock_id)) {
        const resp = await apiCall(ApiUrl.addMerchShipment(), finalPayload);
        if (resp.status) {
          closeModal();
          getMerchList(selectedTrailer);
          toast.success(`Inventory moved successfully`);
        } else {
          if (resp.message) {
            toast.error(resp.message);
          } else {
            toast.error(`Inventory not moved. Please try later`);
          }
        }
      } else {
        toast.error(`From - To Warehouse/Trailer should not be same`);
      }
    } catch (error) {
      console.error("Error updating merch shipment:", error);
      toast.error(`An error occurred while updating merch shipment`);
    }
  };

  const getShipmentTypeLists = async () => {
    try {
      const warehouseResp = await getApiCall(ApiUrl.WarehousesList());
      const trailerResp = await getApiCall(ApiUrl.TrailersList());
      if (warehouseResp.status && trailerResp.status) {
        const combinedList = [
          ...warehouseResp.data.map(item => ({ ...item, type: "warehouse" })),
          ...trailerResp.data.map(item => ({ ...item, type: "trailer" })),
        ];
        return combinedList;
      } else {
        console.error("Failed to fetch warehouse or trailer list");
        return [];
      }
    } catch (error) {
      console.error("Error fetching warehouse or trailer list:", error);
      return [];
    }
  };
  const [isLoading, setIsLoading] = useState(false);

  const addOrUpdateNewTrailer = async (payload) => {
    if (isLoading) return; // Prevent multiple API calls
    setIsLoading(true);
    try {
      const resp = await apiCall(
        TrailerDetails?.id
          ? ApiUrl.TrailerEdit(TrailerDetails?.id)
          : ApiUrl.addTrailer(),
        payload
      );
  
      if (resp.status) {
        closeModal();
        toast.success(
          `Trailer ${TrailerDetails?.id ? "updated" : "added"} successfully`
        );
        const trailersResponse = await getApiCall(ApiUrl.TrailersList());
        if (trailersResponse.status) {
          setTrailersList([
            { value: "_@add Trailer", label: "+ Add Trailer" },
            ...trailersResponse.data.map((item) => ({
              value: item.id,
              label: item.trailer_name,
            })),
          ]);
        }
      } else {
        toast.error(
          `Trailer not ${TrailerDetails?.id ? "updated" : "added"}. Please try later`
        );
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <>
        <ModalCustomTrailers
        showModal={showModal}
        closeModal={closeModal}
        modalProps={modalProps}
        merchDetails={merchDetails}
        updateMerchStocks={updateMerchStocks}
        updateMerchShipment={updateMerchShipment}
        getShipmentTypeLists={getShipmentTypeLists}
        selectedTrailer={selectedTrailer}
        addOrUpdateNewTrailer={addOrUpdateNewTrailer} // Ensure this prop is passed to the modal
        TrailerDetails={TrailerDetails}
        // modalTitle={modalProps.title}
      />
    <Formik
      initialValues={{
        start_date: singleTourDetails ? singleTourDetails.start_date : null,
        end_date: singleTourDetails ? singleTourDetails.end_date : null,
        tour_name: singleTourDetails ? singleTourDetails.tour_name : "",
        trailers:  getPreselectedTrailers(),
        tour_type: singleTourDetails ? singleTourDetails.tour_type : "",
        report_currency: singleTourDetails
          ? singleTourDetails.report_currency
          : "USD $",
        vend_fee:
          singleTourDetails && singleTourDetails.vend_fee === "Enable"
            ? true
            : false,
        merchandise_company: singleTourDetails
          ? singleTourDetails.merchandise_company
          : "",
        merchandise_contact_name: singleTourDetails
          ? singleTourDetails.merchandise_contact_name
          : "",
        merchandise_contact_number: singleTourDetails
          ? singleTourDetails.merchandise_contact_number
          : "",
        vend_percentage: singleTourDetails
          ? singleTourDetails.vend_percentage
          : "",
        //send_settlement:singleTourDetails && singleTourDetails.send_settlement === 'Enable'? true : false,
        vend_type: singleTourDetails ? singleTourDetails.vend_type : "",
      }}
      validationSchema={ToursSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        const formattedValues = {
          ...values,
          // vend_percentage: parsePercentage(values.vend_percentage),
        };
        handleAddOrUpdateTour(formattedValues);
        console.log(formattedValues, "vvv");
      }}
    >
      {({
        handleSubmit,
        isSubmitting,
        errors,
        touched,
        values,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit} className="m-3">
          <>
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-col">
                {modalTitle === "Edit Tour" ? null : (
                  <p className="font-pop text-xl font-medium text-primary">
                    Add Tour
                  </p>
                )}
                <p className="font-pop text-xl font-medium text-primary">
                  {modalTitle}
                </p>
              </div>
              <div className="flex flex-row gap-4">
                <button
                  type="button"
                  className="flex justify-center items-center bg-grey h-8 w-8 rounded-lg shadow-lg"
                  onClick={handleModalClose}
                >
                  <img src={AppImages.cancelBlack} className="w-3 h-3" />
                </button>
                <button
                  type="submit"
                  className="flex justify-center items-center bg-secondary h-8 w-8 rounded-lg shadow-lg"
                >
                  <img src={AppImages.checkIcon} className="w-3 h-3" />
                </button>
              </div>
            </div>
          </>

          <div className="border my-6" />
          <div className="flex flex-col gap-4">
            <div className="flex flex-row justify-between items-center">
              <TextInput
                type="text"
                name="tour_name"
                placeholder="Tour Name"
                rightIcon={null}
                className={`p-2.5 border-placeholderColor ${modalTitle === "Edit Tour"? "w-60": "w-80" }`}
              />
              
              {modalTitle === "Edit Tour" ? (
                <div className="h-16">
             <CustomSelectPicker
  isMulti={true}
  label={""}
  placeHolder={"Select Trailer"}
  data={trailersList}
  name={"trailers"}
  handleCallback={async (selectedOptions) => {
    if (selectedOptions.some((option) => option === "_@add Trailer")) {
      openAddTrailerModal();
    }
    const filteredOptions = selectedOptions.filter(
      (option) => option !== "_@add Trailer"
    );
    setFieldValue("trailers", filteredOptions);   
  }}
  className="px-2.5 w-60 border-placeholderColor"
/>

                  <span className="text-danger text-sm font-pop text-xs">
                    {errors.trailers && touched.trailers && errors.trailers}
                  </span>
                </div>
              ) : (
                ""
              )}
              <div className="h-16">
                <CustomSelectPicker
                  isMulti={false}
                  label={""}
                  placeHolder={"Tour Type"}
                  data={getFormttedTourTypeData(addToursData.tour_types)}
                  name={"tour_type"}
                  className={`px-2.5 border-placeholderColor ${modalTitle === "Edit Tour"? "w-60": "w-80" }`}
                />
                <span className="text-danger text-sm font-pop text-xs">
                  {errors.tour_type && touched.tour_type && errors.tour_type}
                </span>
              </div>
            </div>
            <div className="flex flex-row justify-between items-center ">
              <div className="h-16">
                <CustomDatepicker
                  name="start_date"
                  className="p-2.5 w-60"
                  placeholder="Start date"
                  id="start_date"
                  hanldeSetModalHeight={handleSetAddExpandModal}
                />
                {touched.start_date && errors.start_date ? (
                  <span className="text-danger font-pop text-xs">
                    {errors.start_date}
                  </span>
                ) : null}
              </div>
              <div className="h-16">
                <CustomDatepicker
                  name="end_date"
                  className="p-2.5 w-60 border-placeholderColor"
                  placeholder="End date"
                  id="end_date"
                  startDate={values.start_date}
                  hanldeSetModalHeight={handleSetAddExpandModal}
                />
                {touched.end_date && errors.end_date ? (
                  <span className="text-danger font-pop text-xs">
                    {errors.end_date}
                  </span>
                ) : null}
              </div>
              <div className="h-16">
                <CustomSelectPicker
                  isMulti={false}
                  label={""}
                  placeHolder={"Report Currency"}
                  data={getFormttedCurrencyData(addToursData.currency)}
                  name={"report_currency"}
                  className="px-2.5 w-52 border-placeholderColor"
                />
                <span className="text-danger text-sm font-pop text-xs">
                  {errors.report_currency &&
                    touched.report_currency &&
                    errors.report_currency}
                </span>
              </div>
            </div>
            <div className="flex flex-row gap-8">
              <div className="h-10 flex flex-row">
                <Field type="checkbox" name="vend_fee" />
                <p className="text-secondary font-medium text-xs ml-2 mt-3">
                  Vend Fee
                </p>
              </div>

              {values.vend_fee && (
                <>
                  <div className="h-10">
                    <TextInput
                      type="text"
                      name="vend_percentage"
                      placeholder="Percentage%"
                     
                      className="p-2.5 w-50 border-placeholderColor"
                    />
                  </div>
                  <div className="h-16">
                    <CustomSelectPicker
                      isMulti={false}
                      placeHolder={"Vend Type"}
                      data={VendTypes}
                      name={"vend_type"}
                      className="px-2.5 w-72 border-placeholderColor"
                    />
                  </div>
                </>
              )}
            </div>

            <div className="flex flex-row gap-2 items-center">
              <TextInput
                type="text"
                name="merchandise_company"
                placeholder="Tour Merchandise Company Name"
                rightIcon={null}
                className="p-2.5 w-80 border-placeholderColor"
              />
              <TextInput
                type="text"
                name="merchandise_contact_name"
                placeholder="Tour Merchandise Contact Name"
                rightIcon={null}
                className="p-2.5 w-60 border-placeholderColor"
              />
            </div>
            <div className="flex flex-row gap-2 items-center">

            <TextInput
                type="email"
                name="merchandise_email"
                placeholder="Tour Merchandise Email"
                rightIcon={null}
                className="p-2.5 w-60 border-placeholderColor"
              />
              
              
              <TextInput
                type="text"
                name="merchandise_contact_number"
                placeholder="Tour Merchandise Contact Number"
                rightIcon={null}
                className="p-2.5 w-60 border-placeholderColor"
              />
            </div>
          </div>
        </Form>
      )}
      </Formik>
      </>
  );
};

export default ToursForm;
